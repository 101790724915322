import React, { useContext } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css'; 
import { HouseContext } from './HouseContext';
import './BannerMobile.css'; // Import the mobile CSS file
import { Link } from 'react-router-dom'; // Import Link from React Router

const Banner = () => {
  const { houses } = useContext(HouseContext);

  
  const trendingProperties = houses.filter(house => house.category === 'highlighted');

  
  const settings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
  };

  return (
    <section className='banner-container relative min-h-[60vh] lg:min-h-[90vh] max-h-screen mb-8 xl:mb-24 p-0 flex flex-col'>
      
      <div className='banner-text absolute z-20 p-6 text-white pl-8 md:pl-[70px] animate-slide-in-left'>
        <h1 className='text-3xl md:text-4xl font-bold mb-2 md:mb-4'>Highlighted Properties</h1>
        <p className='text-md md:text-lg'>Discover our exclusive highlighted properties available for rent and sale.</p>
      </div>

      {/* Slick Slider for Trending Properties */}
      <div className='relative w-full flex-1 overflow-hidden' style={{paddingLeft: '20px', paddingRight: '20px'}}>
        <Slider {...settings}>
          {trendingProperties.map((property, index) => (
            <Link to={`/property/${property.id}`} key={index}> {/* Wrap the content in a Link */}
              <div className='relative w-full h-full'>
                {/* Overlay */}
                <div className='absolute inset-0 bg-black opacity-30 rounded-lg z-10'></div>

                {/* Property Image */}
                {property.image_url && property.image_url.length > 0 ? (
                  <img
                  src={`https://navimumbaipropertylist.in/backend/images/${property.image_url[0]}`} 
                    className='w-full h-full object-cover rounded-lg'
                    alt={`Property ${property.id}`}
                  />
                ) : (
                  <div className='w-full h-full bg-gray-300 flex items-center justify-center'>
                    <p className='text-white'>No Image Available</p>
                  </div>
                )}
              </div>
            </Link>
          ))}
        </Slider>
      </div>

      {/* Property Details Container */}
      {trendingProperties.length > 0 && (
        <div className='property-details absolute bottom-4 left-4 text-white z-30 drop-shadow-md p-4 lg:bottom-8 lg:left-8 lg:w-auto lg:text-left'>
          <div className='bg-black bg-opacity-50 p-4 rounded'>
            <h2 className='text-lg sm:text-xl font-semibold'>{trendingProperties[0].type}</h2>
            <p className='text-sm sm:text-lg'>
              {trendingProperties[0].bedrooms} Bedrooms, {trendingProperties[0].bathrooms} Bathrooms
            </p>
          </div>
        </div>
      )}
    </section>
  );
};

export default Banner;
